import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询告警错误码列表
export function listCode(query: any) {
  return request({
    url: "/factory/alertCode/list",
    method: "get",
    params: query,
  });
}
